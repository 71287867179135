<template>
  <div class="onboarding-form">
    <content-loader
      v-if="dataLoading == true"
      viewBox="0 0 476 124"
      primaryColor="#f3f3f3"
      secondaryColor="#cccccc"
    >
      <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
      <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
      <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      <circle cx="20" cy="20" r="20" />
    </content-loader>
    <div v-if="!dataLoading == true" class="pb-5 pt-lg-0 pt-3">
      <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        Waste Streams <small>(Step 6 of 10)</small>
      </h3>
      <h4 class="pb-5">
        Waste streams are the different sources of waste generated in your
        organisation. Keeping track of your waste sources helps you manage and
        gain insight to your waste accumulation and carbon footprint.
      </h4>
      <v-card elevation="2" outlined>
        <form
          @submit.prevent="submit"
          class="form px-4 pt-4"
          autocomplete="false"
        >
          <div class="form-row">
            <div class="col-sm-12 col-md-6">
              <input
                type="text"
                class="form-control"
                placeholder="Waste Stream e.g Paper"
                :class="{ 'is-invalid': $v.name.$error }"
                v-model.trim="$v.name.$model"
              />
              <template v-if="$v.name.$error">
                <span class="form-text text-danger" v-if="!$v.name.required">
                  waste stream is required.
                </span>
              </template>
            </div>
            <!-- <div class="col-sm-12 col-md-4">
              <input
                type="number"
                step="any"
                class="form-control"
                placeholder="Emission factor"
                :class="{ 'is-invalid': $v.emissionFactor.$error }"
                v-model.trim="$v.emissionFactor.$model"
              />
              <template v-if="$v.emissionFactor.$error">
                <span
                  class="form-text text-danger"
                  v-if="!$v.emissionFactor.required"
                >
                  emission factor is required.
                </span>
              </template>
            </div> -->
            <div class="col-sm-12 col-md-5 select-sm">
              <multiselect
                v-model="unit"
                :options="units"
                track-by="name"
                label="name"
                placeholder="Select Unit"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.name
                }}</template>
              </multiselect>
            </div>
            <div class="col-sm-12 col-md-1">
              <button
                :disabled="loading == true"
                type="submit"
                class="btn btn-primary font-weight-bolder btn-block"
              >
                <b-spinner
                  small
                  v-if="loading == true"
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <span v-if="loading == false">ADD</span>
              </button>
            </div>
          </div>
        </form>
        <hr />
        <template>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="streams"
            v-bind:loading="loading"
            loading-text="Loading..."
            :items-per-page="5"
          >
            <template v-slot:[`item.unit`]="{ item }">
              <b-badge variant="primary">{{ item.unit.name }}</b-badge>
            </template>

            <template v-slot:[`item.id`]="{ item }">
              <b-button
                class="btn-xs float-right"
                variant="danger"
                @click="deleteItem(item.id)"
                ><i class="fas fa-trash pr-0"></i
              ></b-button>
            </template>
          </v-data-table>
        </template>
      </v-card>

      <div class="pb-lg-0 pb-5 float-right mt-5">
        <router-link to="/onboarding/energy-streams" v-slot="{ navigate }">
          <button
            type="button"
            class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
            @click="navigate"
          >
            <i class="fas fa-arrow-left ml-2"></i>
            Back
          </button>
        </router-link>

        <button
          type="button"
          class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
          @click="nextStep()"
        >
          <b-spinner
            v-if="loading == true"
            variant="light"
            label="Spinning"
          ></b-spinner>
          <span v-if="loading == false"
            >Next<i class="fas fa-arrow-right ml-2"></i
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { ContentLoader } from "vue-content-loader";
import GeneralService from "@/core/services/general.service.js";
import CompanyService from "@/core/services/company.service.js";
import WasteStreamService from "@/core/services/waste-stream.service.js";
import { mapGetters, mapState } from "vuex";
import { required, decimal } from "vuelidate/lib/validators";
import { UPDATE_AUTH } from "@/core/services/store/auth.module";
export default {
  data() {
    return {
      name: "",
      unit: null,
      emissionFactor: 0.0,
      units: [],
      streams: [],
      headers: [
        {
          text: "Waste Stream",
          align: "start",
          sortable: true,
          value: "name"
        },
        { text: "Unit", value: "unit", sortable: false },
        { text: "Action", value: "id", align: "end", sortable: false }
      ],
      loading: false,
      dataLoading: true
    };
  },
  validations: {
    name: { required },
    emissionFactor: { required, decimal },
    unit: { required }
  },
  components: {
    Multiselect,
    ContentLoader
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"])
  },
  mounted() {
    this.getUnits();
    this.getStreams();
  },

  methods: {
    getUnits() {
      GeneralService.units()
        .then(response => {
          this.units = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate units at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },

    getStreams() {
      WasteStreamService.all()
        .then(response => {
          this.streams = response.data;
          this.dataLoading = false;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate energy streams at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },

    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;

        WasteStreamService.create({
          name: this.name,
          description: this.name,
          emission_factor: this.emissionFactor,
          unit_id: this.unit ? this.unit.id : null
        })
          .then(response => {
            console.log(response);
            this.getStreams();
            this.loading = false;
            this.name = "";
            this.unit = null;
            this.emissionFactor = 0.0;
            this.$nextTick(() => {
              this.$v.$reset();
            });
            this.makeToast(
              "success",
              "Successful",
              "Energy stream was succesfully added"
            );
          })
          .catch(error => {
            if (error.status == 422) {
              let errors = Object.values(error.data.errors);
              errors = errors.flat();
              this.makeToast("danger", "Validation Error", errors[0]);
            } else if (error.status == 400) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a minute and refresh page"
              );
            }
            this.loading = false;
            console.log(error);
          });
      }
    },

    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this stream",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WasteStreamService.delete(id)
            .then(response => {
              this.getStreams();
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            "Stream Deleted",
            "Stream was successfully deleted"
          );
        }
      });
    },

    nextStep() {
      if (this.streams.length == 0) {
        this.makeToast(
          "danger",
          "Validation Error",
          "You are required to add at least one waste stream"
        );
        return;
      }

      this.loading = true;
      var data = {
        previous_onboarding_stage: 6,
        onboarding_stage: 7,
        onboarding_complete: false
      };
      CompanyService.setOnboardingStage(data)
        .then(response => {
          try {
            this.$store.dispatch(UPDATE_AUTH, response.data);
            this.$router.push({ name: "onboarding-observation-classes" });
          } catch (error) {
            console.log(error);
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
            this.loading = false;
          }
        })
        .catch(error => {
          if (error.data) {
            this.makeToast("danger", error.statusText, error.data.message);
          } else {
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
          }
          this.loading = false;
        });
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
